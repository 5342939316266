exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apps-bitcoin-app-js": () => import("./../../../src/pages/apps/bitcoin/app.js" /* webpackChunkName: "component---src-pages-apps-bitcoin-app-js" */),
  "component---src-pages-apps-periodictable-app-js": () => import("./../../../src/pages/apps/periodictable/app.js" /* webpackChunkName: "component---src-pages-apps-periodictable-app-js" */),
  "component---src-pages-apps-periodictable-element-js": () => import("./../../../src/pages/apps/periodictable/Element.js" /* webpackChunkName: "component---src-pages-apps-periodictable-element-js" */),
  "component---src-pages-apps-periodictable-table-js": () => import("./../../../src/pages/apps/periodictable/Table.js" /* webpackChunkName: "component---src-pages-apps-periodictable-table-js" */),
  "component---src-pages-apps-refresher-app-js": () => import("./../../../src/pages/apps/refresher/app.js" /* webpackChunkName: "component---src-pages-apps-refresher-app-js" */),
  "component---src-pages-appsmain-js": () => import("./../../../src/pages/appsmain.js" /* webpackChunkName: "component---src-pages-appsmain-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

